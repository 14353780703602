import { useForm } from 'react-hook-form';
import './App.css';

function App() {

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    const response = await fetch('https://hook.eu1.make.com/bkn2kua8urxfupoqg8w4xr4w3q83lk9o', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    console.log(response);
  }

  return (
    <div className="App">
      <h1>Hablemos de código y programación</h1>
      <h2>¿Sabes que aprender a programar puede cambiarte la vida?</h2>
      <p>
        Seguro que si buscas un poco por internet encontrarás miles de motivos explicándote cómo saber programar puede hacerte ganar un sueldo más que decente y puede conseguirte el trabajo de tus sueños...
      </p>
      <p>
        Lo que no te cuentan es que puede ser que<strong> NO TE GUSTE PROGRAMAR</strong>.
      </p>
      <img src='https://media.giphy.com/media/Z5HVfEvnxr67u/giphy.gif' alt='boom'></img>
      <p>
        El maestro Confucio (parece nombre inventado ¿eh?, pero no, es el auténtico, no el que inventó la confusión) nos dejó esta frase que bien podrías poner en tu estado del messenger:
      </p>
      <h3>"Elige un trabajo que te guste y no tendrás que trabajar ni un día de tu vida"</h3>
      <p>
        Imagina que decides entrar en el mundo de la programación, que gastas tu <strong>tiempo</strong> y tu <strong>dinero</strong> en la formación más completa del mercado y cuando llevas dos o tres meses partiéndote la cara contra el ordenador te das cuenta que <strong>no es realmente a lo que te quieres dedicar.</strong>
      </p>
      <p>
        Nosotros estamos convencidos que <strong>la programación no es para todo el mundo</strong> (aunque a veces te lo queramos vender). Puede ser que no te atraiga, que no te guste las posibilidades laborales que te ofrece o simplemente que no se te de bien.
      </p>
      <p>
        Yo me considero nulo en cualquier arte que incluya el uso de las manos. En casa intento hacer los pequeños arreglos que surgen o cuelgo los cuadros sufriendo mucho con la taladradora (siempre pienso que voy a pinchar una tubería). En ningún caso me planteo, aunque me ofrezcan todo el oro del mundo, entrar a trabajar en ningún trabajo artesanal o que implique manualidad, <strong>simplemente, no lo disfrutaría.</strong>
      </p>
      <h2>Entonces, ¿qué te proponemos?</h2>
      <img src='https://media.giphy.com/media/YSxABIzfohDMAelq41/giphy.gif' alt='listen'></img>
      <p>
        Llevamos <strong>más de 10 años</strong> formando a personas con cero conocimientos en el mundo de la programación y tenemos detectados muchos casos que se repiten de manera habitual:
      </p>
      <div>
        <ul>
          <li>Mi trabajo actual no me permite mantener una conciliación familiar buena y necesito un horario más decente o incluso teletrabajar.</li>
          <li>Me han hablado de los salarios altos en el mundo IT y me vendría muy bien mejorar mis ingresos.</li>
          <li>Todo mi entorno está trabajando en algún puesto relacionado con las nuevas tecnologías y me presionan constantemente para que me cambie.</li>
          <li>Estoy cansado/a de mi actual trabajo y necesito cualquier otra cosa.</li>
        </ul>
      </div>
      <p>
        Si te encuentras en alguno de los casos anteriores, <strong>lo primero que debes aplicar es la tranquilidad y el análisis</strong>. No te metas a programar sin saber qué te espera.
      </p>
      <p>
        La creación de aplicaciones web, aplicaciones móviles o cualquier otro tipo de creación relacionada con alguna tecnología o lenguaje de programación <strong>no es algo que podamos manejar de manera experta en unos pocos meses</strong>. Hay personas que han dedicado toda su vida a programar y aun mantienen sus hábitos de formación e incluso sus momentos de frustración al encontrarse con errores nunca vistos.
      </p>
      <img src='https://media.giphy.com/media/Dh5q0sShxgp13DwrvG/giphy.gif' alt='perro'></img>
      <h2>Nuestra propuesta es que conozcas qué significa programar antes de meterte en más líos</h2>
      <p>
        Si confías en nosotros nos pondremos manos a la obra para hacerte llegar los conceptos de programación más básicos en los lenguajes más punteros del mercado. Con nuestra experiencia conocemos cuáles son los puntos críticos del aprendizaje y te ayudaremos a superarlos de la manera más óptima.
      </p>
      <p>
        Te ofrecemos una <strong>pequeña introducción al mundo del desarrollo</strong> para que tú elijas si puede ser una profesión de futuro o quieres eliminar esa idea de tu cabeza lo antes posible sin gasto de tiempo o de dinero.
      </p>
      <p>Lo único que te pedimos es que nos dejes tu correo electrónico y así te podemos presentar los diferentes planes que tenemos. No vamos a llenarte el correo de promociones absurdas y en el momento que quieras, te dejaremos partir... 👋</p>
      <div>
        <h3>Déjanos tu correo y te pasamos nuestro plan infalible 😉</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input {...register("email", { required: true })} />
          <input type="submit" value="Enviar" />
        </form>
      </div>
    </div >
  );
}

export default App;
